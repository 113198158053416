













import { Component, Vue } from 'vue-property-decorator'

// components
import LinkTabs from '@/components/_uikit/LinkTabs.vue'

@Component({
  components: {
    LinkTabs,
  },
})
export default class Support extends Vue {
  private tabs = [
    {
      name: 'Обращения',
      to: { name: 'manager.support.tickets' },
    },
    {
      name: 'Частые вопросы',
      to: { name: 'manager.support.faq' },
    },
  ]

  private mounted() {
    if (this.$route.name === 'manager.support') {
      this.$router.replace({ name: 'manager.support.tickets' })
    }
  }
}
